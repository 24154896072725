


.custom-navbar {
    background-color: #0f172a; /* Dark blue background */
    padding: 0.3rem; /* Top and bottom padding */
    border-radius: 60px; /* Rounded edges for the navbar */
    margin: 2rem auto; /* Vertical margins with automatic horizontal margins */
    border: none; /* In case there's a border by default */
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), /* Optional: shadow for depth */
               0 2px 4px -2px rgba(0, 0, 0, 0.1);
    width: auto; /* Ensures the navbar isn't stretching the full width */
    max-width: 80%; /* Adjust this value to set the maximum width of the navbar */
}

.container-fluid {
    max-width: 100%; /* Set a max-width that is less than 100% to match the navbar width */
    padding-right: 3%; /* Adjust to match the spacing you want */
    padding-left: 3%; /* Adjust to match the spacing you want */
}

.custom-navbar .navbar-brand {
    display: flex;
    align-items: center;
}

.custom-navbar .navbar-logo .logo {
    width: 40px; /* Adjust as per your logo's aspect ratio */
    height: 40px; /* Adjust as per your logo's aspect ratio */
}

.custom-navbar .navbar-logo .logo-text {
    color: #FFFFFF; /* Logo text color */
    font-weight: bold;
    margin-left: 0.5rem;
}

.custom-navbar .navbar-nav .nav-link {
    color: #FFFFFF; /* Navigation links color */
    margin-right: 2rem; /* Space between navigation links */
}

.custom-navbar .navbar-nav .nav-link:hover {
    background-color: #334155; /* A slightly lighter background color on hover */
    border-radius: 30px; /* Rounded corners for the hover background */
}

.custom-navbar .matic-price {
    color: #FFFFFF; /* MATIC price text color */
    margin-right: 1rem; /* Space between MATIC price and buttons */
}


/* Style for the 'Connect Wallet' button with a white background */
.custom-navbar .signin-btn {
    background-color: #7c3aed; /* White background */
    color: #FFFFFF; /* Dark text color, adjust if necessary */
    border-radius: 20px; /* Rounded corners */
    border: none; /* No border */
    margin-right: 0.5rem; /* Space between buttons */
    padding: 0.375rem 1.5rem; /* Padding to increase size, adjust as necessary */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for color and background */
}

/* Hover style for the 'Connect Wallet' button to have a purple background */
.custom-navbar .signin-btn:hover {
    background-color: #FFFFFF;; /* Purple background on hover */
    color: #0f172a; /* White text on hover */
    border: none; /* Ensure there's no border on hover if there was one initially */
}
.custom-navbar .twitter-btn {
    background-color: #00acee; /* Twitter button color */
    border: none;
    margin-right: 0.5rem; /* Space between buttons */
}

.custom-navbar .twitter-btn:hover {
    background-color: #007bb5; /* Twitter button hover color */
}

.custom-navbar .navbar-collapse {
    flex-grow: 0;
}

@media (max-width: 991px) {
    .custom-navbar .navbar-collapse {
        background-color: transparent /* Adjust to match the design's dark blue */
    }
}

.custom-navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 991px) {
    .custom-navbar .navbar-nav {
        display: flex;
        flex-direction: column;
        align-items: center; /* Align buttons in the center */
        width: 100%; /* Take full width to center content */
    }

    .custom-navbar .signin-btn,
    .custom-navbar .twitter-btn {
        width: 60%; /* Adjust the width as needed */
        padding: 0.375rem 0.75rem; /* Adjust padding to reduce size */
        font-size: 1rem; /* Optional: adjust font size for smaller screens */
        margin-bottom: 0.5rem; /* Add some space between the buttons */
    }
    
    /* If you have specific classes or containers for the buttons, you might need to adjust the selectors accordingly. */
}

.wallet-status-dot {
    height: 10px;
    width: 10px;
    background-color: red; /* Default color */
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px; /* Adjust spacing as needed */
    vertical-align: middle;
  }