/* Global Styles */

html,
body {
  overflow-y: auto;
}

/* App Container */
.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #5c60f5 0%, #8957ff 80%, #fb0 100%);
  color: white;
  font-family: 'Arial', sans-serif;

}

.App::before {
  content: '';
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  filter: blur(10px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}


/* Form Container */

.info-text {
  margin-bottom: -250px; /* Adjust the margin bottom */;
}


/* Container für FELD1 und FELD2*/

.cardtop {
  color: #ffffff; /* White text color */
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 40px;
  border: 5px solid transparent; /* White border */
  background: transparent;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
  max-width: 100%;
  padding: 30px;
  letter-spacing: 0.05em;
}




/* Beschriftung von FELD mit Bild */

div[class="cardimageoben"] {
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardimageoben img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 250px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

/* Container für Feld mit Bild*/
 .cardimageoben{
  background: transparent;
  box-shadow: transparent;

}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimageoben img {
  animation: floatAnimation 3s ease-in-out infinite;
}





div[class="cardimagephonescreen"] {
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cardimagephonescreen img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 150px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimagephonescreen img {
  animation: floatAnimation 3s ease-in-out infinite;
}



div[class="winner_human"] {
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.winner_human img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 150px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.winner_human img {
  animation: floatAnimation 3s ease-in-out infinite;
}


div[class="voting"] {
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.voting img {
  width: 150%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 250px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.voting img {
  animation: floatAnimation 3s ease-in-out infinite;
}




div[class="cardimagejackpot"] {
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
}

.cardimagejackpot img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 130px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimagejackpot img {
  animation: floatAnimation 3s ease-in-out infinite;
}



div[class="cardimagegraph"] {
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
}

.cardimagegraph img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 130px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimagegraph img {
  animation: floatAnimation 3s ease-in-out infinite;
}




div[class="cardimagehandmoney"] {
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
}

.cardimagehandmoney img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 120px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimagehandmoney img {
  animation: floatAnimation 3s ease-in-out infinite;
}



div[class="cardimageticket"] {
  color: #000000;
  margin-top: 0px;
  margin-bottom: 10px;
}

.cardimageticket img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 100px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}


@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.cardimageticket img {
  animation: floatAnimation 3s ease-in-out infinite;
}




/* Beschriftung von FELD mit Bild */

div[class="cardimagemitte"] {
  color: #000000;
  margin-top: -20px;
  margin-bottom: -0px;
}

.cardimagemitte img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 80px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}


/* This class will be used to contain the video and can include media queries for responsiveness */
.video-container {
  max-width: 100%; /* Limits the video width to not exceed the width of its container */
  margin: auto; /* Centers the video in the container */
}

/* For large screens where lg breakpoint applies in Bootstrap (usually screens >=992px) */
@media (min-width: 992px) {
  .video-container {
    max-width: 80%; /* You can adjust this value to whatever you think is best */
    /* Add any additional styling you need for large screens here */
  }
}


div[class="cardimagemeta"] {
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px;
  border: 5px solid;
  border-color: transparent;
  box-shadow: transparent; /* White shadow with opacity */
  padding:30px;
  background: transparent;
  
}

.cardimagemeta img {
  width: 100%; /* Adjust width as necessary */
  height: auto; /* Keep the aspect ratio */
  max-width: 300px; /* Maximum width of the image */
  object-fit: cover; /* Keeps the aspect ratio, can be changed to 'contain' to see the full image */
  margin: 0 auto; /* Center the image within the cardimage div if smaller than the container */
}





/* Beschriftung von Prize Pot / in prizes / Number of Tickets
und Information Text und Contract Adresse */

div[class="card"] {
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px;
  border: 5px solid;
  border-color: transparent;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
  padding: 30px;
  background: transparent;

}

/* Countdown Container*/
.card1{

  font-size: 20px;

  letter-spacing: 0.05em;
  background: transparent;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
  max-width: 100%;
  margin-top: 10px;
  padding: 30px;


}
/* Style Countdown Zahlen  */

div[class="card1"] {
  color: #000000;
  margin-bottom: 30px;
  border-radius: 40px;
  border: 5px solid;
  border-color: transparent;


}

/* Buy Button und Get Token Count Button Style*/

button[class="btn btn-primary1"] {
  background-color: #0052FF  ;
  color: #ffffff ;
  border-radius: 40px;
  margin-top: 10px;
  align-self: center;
  margin-bottom: 10px;
  padding: 10px;
  width: 200px;
}


/* Buy Button Hover Style*/

button[class="btn btn-primary1"]:hover {
  background-color: #0043d3;
  color:  #ffffff ;

}




/* Price/Token: 0.1 Matic Text */

.text {
  font-size: 20px;
  color: #000000;
}


/* Scrollbar Styles */

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

/* Style the scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Hide the scrollbar when not hovered */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Header Styles */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 80px;
  background-color: transparent;

}

.buttonss {
  display: flex;
  justify-content: flex-end;

}



/* Allgemeine Stile für das Eingabefeld */
input {
  width: 30%;
  padding: 8px; /* Kleinere Polsterung */
  margin-top: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 3px solid #ffffff;
  background: transparent;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  font-size: 20px; /* Kleinere Schriftgröße */
  text-align: center; /* Zentriert den Text im Eingabefeld */
}

/* Fokussierter Zustand des Eingabefelds */
input:focus {
  outline: none;
  border-color: #ffffff;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.7);   
}







.buy-ticket-container {
  background-color: transparent; /* Or any color you prefer */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
}





.cost-display p {
  color: #ffffff; /* Or any color that suits your design */
  font-size: 1.1rem; /* Adjust the size as needed */
}

/* Buy Ticket Input Group Styles */
.input-group .form-control {
  background-color: transparent; /* Transparent background */
  color: #fff;
  border-radius: 30px;
  border: 5px solid;
  border-color: transparent;
  height: 40px; /* Set the height as needed to match your design */
  margin: 0; /* Remove any default margins */
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5);
}

.input-group .form-control:focus {
  background-color: transparent; /* Transparent background */
  color: #fff;
  border-radius: 30px;
  border: 5px solid;
  border-color: transparent;
  height: 40px; /* Set the height as needed to match your design */
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5);
}


.input-group-append {
  background-color: transparent; /* Transparent background */
  color: #fff;
  border-radius: 30px;
  border: 5px solid;
  border-color: transparent;
  height: 40px; /* Set the height as needed to match your design */
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
}

.input-group-append .input-group-text {
  background-color: transparent; /* Transparent background */
  color: #fff; /* White text */
  height: 40px; /* Set the height as needed to match your design */
  border: 5px solid;
  border-color: transparent;

}

 /* App.css or CustomNavbar.css */
.increment-btn {
  background-color: transparent; /* Green background */
  color: white;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px; /* Set the height as needed to match your design */
padding: 0 15px; /* Adjust padding to your liking */
margin: 0px; /* Remove any default margins */
}

.increment-btn:hover {
  background-color: #45a049;
}

.decrement-btn {
  background-color: transparent; /* Green background */
  color: white;
  border: 1px solid;
  border-color: transparent;
  box-shadow: 1px 1px 10px 1px rgba(255, 255, 255, 0.5); /* White shadow with opacity */
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  height: 40px; /* Set the height as needed to match your design */
  padding: 0 15px; /* Adjust padding to your liking */
  transition: background-color 0.3s ease;
}

.decrement-btn:hover {
  background-color: #d32f2f;
}
